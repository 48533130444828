import { type } from "jquery";
import { defineStore } from "pinia";

export const useBacktestMemoryStore = defineStore("backtestMemory", {
  state: () => ({
    fileMemory: null,
    firstLineMemory: "",
    ignoraLinhaDuplicadaMemory: true,
    layoutMemory: {},
    separadorDefinidoMemory: "",
    mapeamentoMemory: "",
    mapeamentoMemoryObj: {},
    mapformatoMemory: "",
    mapformatoMemoryObj: {},
    mapeamentoVerifyMemory: {},
    checkboxVar: {},
    rotaConsulta: "",
    abordagemConsulta: "",
    layoutConsulta: {},

    identificadorPrimario: "",
    identificadorSecundario: "",
    liberaParametrosAvancados: false,
  }),

  actions: {
    setAbordagem(rota, box, layout) {
      this.abordagemConsulta = box;
      this.rotaConsulta = rota;
      this.layoutConsulta = layout;
    },

    setFile(file, line) {
      this.fileMemory = file;
      this.firstLineMemory = line;
    },

    setIgnoraLinhas(ignora) {
      this.ignoraLinhaDuplicadaMemory = ignora;
    },

    setLayout(layout) {
      this.layoutMemory = layout;
    },
    setSeparador(separador) {
      this.separadorDefinidoMemory = separador;
    },

    setMapeamento(mapeamento, index) {
      this.mapeamentoMemory = mapeamento;
      this.mapeamentoMemoryObj[index] = this.mapeamentoMemory;
    },

    setMapFormato(mapformato, index) {
      this.mapformatoMemory = mapformato;
      this.mapformatoMemoryObj[index] = this.mapformatoMemory;
    },

    setMapeamentoVerify(verif) {
      this.mapeamentoVerifyMemory = verif;
    },

    resetMapeamento() {
      this.separadorDefinidoMemory = "";
      this.mapeamentoMemoryObj = {};
      for (let item in this.checkboxVar) {
        this.checkboxVar[item] = true;
      }
    },

    resetAll() {
      this.fileMemory = null;
      this.firstLineMemory = "";
      this.layoutMemory = {};
      this.ignoraLinhaDuplicadaMemory = true;
      this.separadorDefinidoMemory = "";
      this.mapeamentoMemory = "";
      this.mapeamentoMemoryObj = {};
      this.rotaConsulta = "";
      this.abordagemConsulta = "";
      this.identificadorPrimario = "";
      this.identificadorSecundario = "";
      this.liberaParametrosAvancados = false;
    },

    setCheckboxVar(layout, value) {
      this.checkboxVar[layout] = value;
    },
  },
});
