import { defineStore } from "pinia";
import { apiService } from "../services";
import { authHeader } from "../helpers";
import { chatService } from "@/services";

import hljs from "highlight.js";
import "highlight.js/styles/agate.css";
export const chatOutboxFormatacoesStore = defineStore("chatOutboxFormatacoes", {
  state: () => ({
    textoCodigo: "",
  }),

  actions: {
    limpezaTagsIncompletas(html) {
      const tags = [
        "strong",
        "em",
        "b",
        "i",
        "u",
        "li",
        "ul",
        "ol",
        "p",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "div",
        "span",
        "a",
        "img",
        "table",
        "tr",
        "td",
        "th",
        "thead",
        "tbody",
        "tfoot",
        "blockquote",
        "code",
        "pre",
        "label",
        "hr",
        "sup",
        "sub",
        "dl",
        "dt",
        "dd",
        "figure",
        "figcaption",
        "article",
        "section",
        "header",
        "footer",
        "aside",
        "details",
        "summary",
        "small",
        "cite",
        "abbr",
        "mark",
      ];
      tags.forEach((tag) => {
        const regexQuoted = new RegExp(
          `(['"\`])<${tag}([^>]*?)>(['"\`])`,
          "gi"
        );
        html = html.replace(
          regexQuoted,
          (match, openQuote, tagContent, closeQuote) => {
            if (openQuote === closeQuote) {
              return `${openQuote}&lt;${tag}${tagContent}&gt;${closeQuote}`;
            }
            return match;
          }
        );
      });

      return html;
    },

    formataTextoCodigo(inputString) {
      const regex = /```(.*?)```/gs;

      const formattedString = inputString.replace(regex, (match, code) => {
        let fixedCode = code;
        // let fixedCode = code.replace(/<br\s*\/?>/gi, "\n");

        const firstLine = fixedCode.split("\n")[0];
        const languageDetected = firstLine.trim().split(" ")[0];

        const codeWithoutLanguage = fixedCode
          .substring(firstLine.length)
          .trim();
        const result = hljs.highlightAuto(codeWithoutLanguage);
        const highlightedCode = result.value;

        const estiloBotao =
          "border: none; " +
          "background: none; " +
          "cursor: pointer; " +
          "color: white; " +
          "font-weight: 300; " +
          "font-size: 12px; " +
          "display: flex; " +
          "align-items: center; " +
          "justify-content: center; " +
          "gap: 5px; ";

        return `
        <div class="code-box">
          <div class="code-header">
            ${languageDetected}
            <button class="linkCopiarCodigo" style="${estiloBotao}" data-code="${encodeURIComponent(
          codeWithoutLanguage
        )}">
              <span class="material-symbols-outlined linkCopiarCodigo" data-code="${encodeURIComponent(
                codeWithoutLanguage
              )}">content_copy</span>
              Copiar
            </button>
          </div>
          <pre><code class="code-block">${highlightedCode}</code></pre>
        </div>
        `;
      });

      return formattedString.replace(/(<\/div>\s*)\n+\s*(<pre>)/gi, "$1$2");
    },

    formataTextoLink(texto) {
      const padroesLink = {
        "[link_carrossel]": "[link_carrossel_end]",
        "[link_perguntas]": "[link_perguntas_end]",
      };

      let linkInfo = "";

      for (const [inicioTag, fimTag] of Object.entries(padroesLink)) {
        if (texto.includes(inicioTag) && texto.includes(fimTag)) {
          linkInfo = { inicioTag, fimTag };
        }
      }

      if (!linkInfo) {
        return texto;
      }

      const { textoAntes, link, textoDepois, tipoLink } =
        this.processaTextoComLink(texto, linkInfo);

      const linkFormatado = `<a href="#" class="linkDinamico chat-link" data-tipo="${tipoLink}">${link.trim()}</a>`;
      return `${textoAntes}${linkFormatado}${textoDepois}`;
    },

    processaTextoComLink(texto, linkInfo) {
      const { inicioTag, fimTag } = linkInfo;
      const inicioIdx = texto.indexOf(inicioTag);
      const fimIdx = texto.indexOf(fimTag) + fimTag.length;
      const textoAntes = texto.substring(0, inicioIdx);
      const linkTexto = texto.substring(
        inicioIdx + inicioTag.length,
        fimIdx - fimTag.length
      );
      const textoDepois = texto.substring(fimIdx);

      return { textoAntes, link: linkTexto, textoDepois, tipoLink: inicioTag };
    },

    formataTextoTabela(inputString) {
      const regex = /\[table\](.*?)\[table_end\]/gs;

      const formattedString = inputString.replace(
        regex,
        (match, tableContent) => {
          const rows = tableContent.trim().split("\n");
          let tableHtml =
            '<table style="width:100%; border-collapse: collapse;">';

          rows.forEach((row, index) => {
            row = row.replace(/<br\s*\/?>/gi, "\n");

            const columns = row.split(";");
            tableHtml += `<tr>`;
            if (index === 0) {
              columns.forEach((column) => {
                tableHtml += `<th style="border: 1px solid black; padding: 8px;">${column}</th>`;
              });
            } else {
              columns.forEach((column) => {
                tableHtml += `<td style="border: 1px solid black; padding: 8px;">${column}</td>`;
              });
            }
            tableHtml += `</tr>`;
          });

          tableHtml += "</table>";
          return tableHtml;
        }
      );

      return formattedString;
    },

    processarMarkdown(texto) {
      const negritoRegex = /\*\*(.*?)\*\*/g;
      const italicoRegex = /\*(.*?)\*/g;

      texto = texto.replace(negritoRegex, "<strong>$1</strong>");
      texto = texto.replace(italicoRegex, "<em>$1</em>");

      return texto;
    },

    formatarQuebrasDeLinha(texto) {
      let outside = true;
      return texto
        .split(/(<div class="code-box">|<\/div>)/gi)
        .map((part) => {
          if (
            part.toLowerCase() === '<div class="code-box">' ||
            part.toLowerCase() === "</div>"
          ) {
            outside = !outside;
            return part;
          }
          return outside ? part.replace(/\n/g, "<br>") : part;
        })
        .join("");
    },
  },
});
