import { authHeader } from "../helpers";
import { apiService } from "../services";
import { authHeaderPost } from "../helpers";
import axios from "axios";

export const consultasService = {
  recuperaPermissoes,
  realizaConsulta,
  listaDeProdutos,
  defineRequeridos,
  // realizaConsulta,
  submeteLote,
  iniciaLote,
  pausaLote,
  cancelaLote,
  validaLote,
  realizaConsultaModelo,
  realizaConsultaBox,
};

function listaDeProdutos() {
  var listaProdutos = [
    "BOXATRPF001",
    "BOXATRPF002",
    "BOXATRPF003",
    "BOXATRPF004",
    "BOXATRPF005",
    "BOXATRPF006",
    "BOXATRDAY001",
    "BOXATRPJ001",
    "BOXATRPJ002",
    "BOXATRPJ003",
    "BOXATRPJ004",
    "BOXATRGEO001",
    "BOXATRGEO002",
  ];
  return listaProdutos;
}

function recuperaPermissoes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = apiService.enderecoApiConta() + "/conta/rotas";

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function realizaConsulta(camposRequest) {
  const query = new URLSearchParams(camposRequest);

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = apiService.enderecoApi() + "/v3/consulta" + "?" + query.toString();
  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function realizaConsultaModelo(camposRequest) {
  const query = new URLSearchParams(camposRequest);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(camposRequest),
  };

  let url = apiService.enderecoApi() + "/v3/modelo";
  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}
function realizaConsultaBox(camposRequest) {
  const query = new URLSearchParams(camposRequest);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(camposRequest),
  };

  let url = apiService.enderecoApi() + "/v3/box";
  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function defineRequeridos(contexto_requeridos_idx) {
  var arrayAux = this.contexto_requeridos[contexto_requeridos_idx];
  this.produtosSelecionados.forEach((produto) => {
    arrayAux[produto.Box] = true;
  });
  return arrayAux;
}

async function submeteLote(camposRequest) {
  var formData = new FormData();
  var tipoPessoa =
    camposRequest.tipo_pessoa == "Pessoa Física"
      ? "pessoa_fisica"
      : "pessoa_juridica";

  formData.append("produto", camposRequest.produto);
  formData.append("cod_ref", camposRequest.cod_ref);
  formData.append("tipo_pessoa", tipoPessoa);
  formData.append("caracter_separador", camposRequest.caracter_separador);
  formData.append("mapeamento_cabecalho", camposRequest.mapeamento_cabecalho);
  formData.append("mapeamento_formatos", camposRequest.mapeamento_formatos);
  formData.append(
    "ignorar_linhas_duplicadas",
    camposRequest.ignorar_linhas_duplicadas
  );
  formData.append("nome_projeto", camposRequest.nome_projeto);
  formData.append("arquivo", camposRequest.arquivo);
  formData.append(
    "identificador_etiqueta",
    camposRequest.identificador_etiqueta
  );
  formData.append(
    "identificadores_etiquetas_secundarias",
    camposRequest.identificadores_etiquetas_secundarias
  );
  formData.append("concorrencia_configurada", "1");

  let url = apiService.enderecoApiLotes() + "/lote/cria";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function iniciaLote(camposRequest) {
  var formData = new FormData();

  formData.append("identificacaoExecucao", camposRequest.identificacaoExecucao);

  let url = apiService.enderecoApiLotes() + "/lote/inicia";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}
async function pausaLote(camposRequest) {
  var formData = new FormData();

  formData.append("identificacaoExecucao", camposRequest.identificacaoExecucao);

  let url = apiService.enderecoApiLotes() + "/lote/pausa";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function cancelaLote(camposRequest) {
  var formData = new FormData();

  formData.append("identificacaoExecucao", camposRequest.identificacaoExecucao);

  let url = apiService.enderecoApiLotes() + "/lote/cancela";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function validaLote(camposRequest) {
  var formData = new FormData();
  var tipoPessoa =
    camposRequest.tipo_pessoa == "Pessoa Física"
      ? "pessoa_fisica"
      : "pessoa_juridica";

  formData.append("produto", camposRequest.produto);
  formData.append("cod_ref", camposRequest.cod_ref);
  formData.append("tipo_pessoa", tipoPessoa);
  formData.append("caracter_separador", camposRequest.caracter_separador);
  formData.append("mapeamento_cabecalho", camposRequest.mapeamento_cabecalho);
  formData.append("mapeamento_formatos", camposRequest.mapeamento_formatos);
  formData.append(
    "ignorar_linhas_duplicadas",
    camposRequest.ignorar_linhas_duplicadas
  );
  formData.append("nome_projeto", camposRequest.nome_projeto);
  formData.append("arquivo", camposRequest.arquivo);

  let url = apiService.enderecoApiLotes() + "/lote/valida";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}
