import { defineStore } from "pinia";
import { apiService } from "../services";
import { authHeader } from "../helpers";
import { chatService } from "@/services";

export const chatOutboxHistoricoStore = defineStore("chatOutboxHistorico", {
  state: () => ({
    resultadoListaThreadsGeral: [],
    resultadoListaHistoricoGeral: [],
    resultadoHistoricoContagemGeral: [],

    resultadoListaHistoricoConsorcio: [],
    resultadoHistoricoContagemConsorcio: [],
  }),

  actions: {
    resetStoreChat() {
      this.resultadoListaHistoricoGeral = [];
      this.resultadoHistoricoContagemGeral = [];

      this.resultadoListaHistoricoConsorcio = [];
      this.resultadoHistoricoContagemConsorcio = [];
    },

    async recuperarListaThreads() {
      try {
        this.resultadoListaThreadsGeral =
          await chatService.recuperaListaThreads();
      } catch (error) {
        this.resultadoListaThreadsGeral = error;
      }
    },

    async recuperarHistoricoChatGeral(camposRequest) {
      const query = new URLSearchParams(camposRequest);
      try {
        this.resultadoListaHistoricoGeral =
          await chatService.recuperaHistoricoChat(
            query.toString(),
            "singu_open"
          );
      } catch (error) {
        this.resultadoListaHistoricoGeral = error;
      }
    },

    async recuperarHistoricoChatGeralContagem(camposRequest) {
      const query = new URLSearchParams(camposRequest);

      try {
        this.resultadoHistoricoContagemGeral =
          await chatService.recuperaHistoricoChatContagem(
            query.toString(),
            "singu_open"
          );
      } catch (error) {
        this.resultadoHistoricoContagemGeral = error;
      }
    },
    async recuperarHistoricoChatConsorcio(camposRequest) {
      const query = new URLSearchParams(camposRequest);
      try {
        this.resultadoListaHistoricoConsorcio =
          await chatService.recuperaHistoricoChat(
            query.toString(),
            "consorcio"
          );
      } catch (error) {
        this.resultadoListaHistoricoConsorcio = error;
      }
    },

    async recuperarHistoricoChatConsorcioContagem(camposRequest) {
      const query = new URLSearchParams(camposRequest);
      try {
        this.resultadoHistoricoContagemConsorcio =
          await chatService.recuperaHistoricoChatContagem(
            query.toString(),
            "consorcio"
          );
      } catch (error) {
        this.resultadoHistoricoContagemConsorcio = error;
      }
    },
  },
});
